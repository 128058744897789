import Vue from 'vue';
import Vuex from 'vuex';
import axiosInstance from '../api/https';
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        // 网络请求得到的源数据
        totalDataSource: {},

        // 是否显示开发日志遮罩层
        isShowHeaderDevLogCover: false,
        // 开发日志文本
        headerDevLogDetail: "",

        // 是否显示knowledgeItem遮罩层
        isShowKnowledgeItemCover: false,
        // 当前点击的item数据
        knowledgeItemData: {},

    },
    getters: {
        shareVideoInfo(state) {
            return state.totalDataSource.shareVideoInfo;
        },
        knowledgeInfo(state) {
            return state.totalDataSource.knowledgeInfo;
        },
        appRecommendInfo(state) {
            return state.totalDataSource.appInfo;
        },
        foodVideoInfo(state) {
            return state.totalDataSource.foodVideoInfo;
        },
        sharePictureInfo(state) {
            return state.totalDataSource.pictureInfo;
        }
    },
    actions: {
        // 请求数据
        requestData(context) {
            axiosInstance.get('/websiteDataSource.json').then(_d => {
                // console.log("get得到数据");
                // console.log(_d.data.data);
                context.commit('saveData', _d.data.data);
            });
        }
    },
    mutations: {
        // 存储数据
        saveData(state, data) {
            state.totalDataSource = data;
            // console.log(state.totalDataSource);
        },
        // 修改遮罩层是否显示
        changeIsShowDevLog(state, value) {
            state.isShowHeaderDevLogCover = value.isShow;
            if (value.text) {
                state.headerDevLogDetail = value.text;
            }
        },
        changeIsShowKnowledgeItem(state, value) {
            state.isShowKnowledgeItemCover = value.isShow;
            if (value.itemData) {
                state.knowledgeItemData = value.itemData;
            }
        },
    }
});

export default store;