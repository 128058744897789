const ShareVideo = () =>
    import ('../pages/Tab_1_ShareVideo');
const Knowledge = () =>
    import ('../pages/Tab_2_Knowledge');
const AppRecommend = () =>
    import ('../pages/Tab_3_AppRecommend');
const FoodVideo = () =>
    import ('../pages/Tab_4_FoodVideo');
const SharePicture = () =>
    import ('../pages/Tab_5_SharePicture');
const LearningEnglish = () =>
    import ('../pages/Tab_6_LearningEnglish');
const MyProject = () =>
    import ('../pages/Tab_MyProject');
const MyProject_project = () =>
    import ('../pages/Tab_MyProject_project');
const MyProject_demo = () =>
    import ('../pages/Tab_MyProject_demo');

const route = [{
        path: "",
        redirect: "/sharevideo"
    },
    {
        path: "/sharevideo",
        name: "ShareVideo",
        component: ShareVideo,
    },
    {
        path: "/knowledge",
        name: "Knowledge",
        component: Knowledge,
    },
    {
        path: "/apprecommend",
        name: "AppRecommend",
        component: AppRecommend,
    },
    {
        path: "/foodvideo",
        name: "FoodVideo",
        component: FoodVideo,
    },
    {
        path: "/sharepicture",
        name: "SharePicture",
        component: SharePicture,
    },
    {
        path: "/learningenglish",
        name: "LearningEnglish",
        component: LearningEnglish,
    },
    {
        path: "/myproject",
        component: MyProject,
        children: [{
            path: "",
            redirect: "/project"
        }, {
            path: '/project',
            name: "Project",
            component: MyProject_project,
        }, {
            path: '/demo',
            name: "Demo",
            component: MyProject_demo,
        }]
    },
];

export default route;