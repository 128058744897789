const logText = {
    info: `使用Vue框架重写及重新设计个人网站页面\n
Jul 23, 2022\n
完成整体UI功能界面，分类按钮，并添加个人博客链接\n
添加时间日期模块，实时显示时间日期\n
根据媒体查询添加一些装饰内容以适应大屏显示\n
内容展示区域初步搭建，分类按钮实现路由规则切换组件\n
添加导航栏与返回顶部按钮，实现显示与隐藏逻辑，添加路由切换功能\n
添加页面滚动逻辑，封装缓动动画，点击按钮滚动到页面指定位置\n
页面底部添加网站备案信息\n
\n
Aug 1, 2022\n
尝试使用Sass增强css功能(然并卵，没用到)\n
本地生成静态数据，通过渲染item组件展示到页面上，点击组件跳转指定链接\n
实现按时间段随机切换背景图片\n
(这里我直接使用js获取body元素来切换背景图片，实在没辙了)\n
解决页面滚动bug，当点击按钮滚动页面的动画期间，\n
如果使用了鼠标滚轮 页面会在滚动结束位置上下弹跳，\n
这里我没有找到问题所在，直接为窗口添加监听，\n
点击按钮页面滚动期间 禁用鼠标滚轮操作，但后续仍有bug\n
Nginx服务器中配置重定向，解决路由history模式下，刷新404的问题\n
\n
Aug 2, 2022\n
shareVideo组件基本完成数据展示，待进一步优化\n
\n
Aug 3, 2022\n
页面头部添加github仓库链接\n
shareVideo组件底部添加分页器，引入并使用全局less以增强css功能\n
模拟生成大量数据，shareVideo组件显示正常\n
完善页面总体布局，shareVideo组件底部闭合浮动，由其内部数据撑开高度\n
(但还是要设置最低高度限制，防止页面过短出现bug)\n
根组件取消高度设置，高度跟随组件内大小变动\n
禁止选中网页文本 使用js为body添加监听事件，取消选中默认行为\n
封装axios拦截器，在app组件创建完毕时发起请求获取后台数据\n
在fastmock网站 创建网络接口 mock数据，模拟网站从后台获取数据\n
(注：mock数据暂无跨域问题)\n
添加Vuex以存取公共数据，在getters中处理后供各个组件调取\n
解决无法及时获取请求数据而组件初始化失败的问题\n
(封装初始化步骤，监听数据源变化)\n
\n
Aug 4, 2022\n
底部免责声明 微调底部距离\n
新增mock.json文件，存放模拟请求的后端数据\n
内容展示组件中新增等待加载模块，在页面未获取到数据时占位\n
计算数据增加判断逻辑，在没有获取数据时创建一个假数据，\n
防止重新激活路由时访问空数据源时报错\n
解决 切换路由页面不展示内容，刷新后页面正常显示的问题，\n
在路由激活钩子中重新初始化组件\n
其他内容展示模块完成基本数据展示\n
我的项目组件内容 分开为project、demo两个子路由组件显示\n
Item组件更名为ViewItem，用于展示视图类内容\n
新增TextItem组件，用于展示文本类内容\n
彻底修复页面滚动bug，\n
由于缓动动画 取页面偏移值pageYOffset 非整数 导致，\n
新增逻辑 数据向上/向下取整\n
\n
Aug 5, 2022\n
属性titleDetail改为titleInfo，新增detail 属性用于textItem中显示\n
完成textItem组件展示数据，溢出文本省略号显示\n
item组件添加判断条件，未接收到数据不显示相应元素，添加hover效果\n
补充剩余模块mock数据\n
添加 开发日志图标，上下浮动效果\n
调整project与demo组件数据源改为本地数据，调整初始化位置\n
修复project与demo组件路由bug\n
\n
Aug 6, 2022\n
开发日志 按钮点开显示遮罩层，然后展示开发日志文本内容\n
通过引入本地文件的方式，引入开发日志文本\n
knowledge foodvideo project组件的Item点击显示遮罩层\n
(目前还有bug，视情况决定是否保留逻辑)\n
\n
Aug 7, 2022\n
实现pageheader knowledge 组件的遮罩层逻辑\n
遮罩层结构样式及关闭函数 移到app组件\n
显示遮罩层的函数及点击事件 在子组件\n
Vuex存储展示状态，app组件读取状态显示/隐藏遮罩层\n
子组件在显示函数中 修改显示状态并传递参数\n
knowledge遮罩层显示数据待优化\n
\n
Aug 8, 2022\n
其他日程安排，开发暂时搁置\n
\n
Nov 16, 2023\n
添加公安部备案信息\n
\n
之后的规划\n
优化开发日志在小屏幕的显示\n
优化knowledge遮罩层数据显示\n
实现foodvideo project组件的Item遮罩层逻辑\n
优化页面文本禁止选中逻辑\n
SEO优化\n
完善project demo组件数据\n
\n
mock网站寄了，以后再整，摆了
`
};

export default logText;