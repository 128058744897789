<template>
      <div class="pageHeader">
        <!-- logo -->
        <div class="logo">
            <img src="../assets/icon/pumpkin.webp" alt="">南瓜头の知识分享站
        </div>
        <!-- 时间模块 -->
        <div class="timer">
            <span>{{nowTime.hour}}</span>:<span>{{nowTime.minute}}</span>:<span>{{nowTime.second}}</span>
            <div class="thisDay">{{nowTime.thisDay}}</div>
            <div class="greeting">{{nowTime.greeting}}</div>
            <div class="logButton" @click="showDevLogCover"><img src="../assets/icon/log.png"></div>
        </div>
        <!-- 开发日志 遮罩层，移到app组件中展示 -->
        <!-- <div class="coverLog" v-if="isShowCoverLog" @click="hideCoverLog">
            <div class="devlog">
                <div class="logContainer">
                    <div class="logTitle">开发日志</div>
                    <div class="logContent">{{logDetail}}</div>
                </div>
            </div>
        </div> -->
        <!-- 项目、博客、github -->
        <div class="blogButton">
            <router-link replace to="/myproject" @click.native="moveToTabContent">
                <img src="../assets/icon/project.png" >
                <span>我的项目</span>
            </router-link>
            <a href="https://chiseledpumpkin.github.io">
                <img src="../assets/icon/blog.png" >
                <span>我的博客</span>
            </a>
            <a href="https://github.com/ChiseledPumpkin">
                <img src="../assets/icon/GitHub.png" >
                <span>GitHub</span>
            </a>
        </div>
        <!-- 分类按钮 -->
        <div class="tab_list">
            <ul>
                <li>
                    <router-link replace to="/sharevideo" @click.native="moveToTabContent">
                        <img src="../assets/icon/bilibili.png" >
                        <span>知识视频分享</span>
                    </router-link>
                </li>
                <li>
                    <router-link replace to="/knowledge" @click.native="moveToTabContent">
                        <img src="../assets/icon/science.png" >
                        <span>野生知识</span>
                    </router-link>
                </li>
                <li>
                    <router-link replace to="/apprecommend" @click.native="moveToTabContent">
                        <img src="../assets/icon/APP.png" >
                        <span>软件推荐</span>
                    </router-link>
                </li>
                <li>
                    <router-link replace to="/foodvideo" @click.native="moveToTabContent">
                        <img src="../assets/icon/food.png" >
                        <span>美食视频</span>
                    </router-link>
                </li>
                <li>
                    <router-link replace to="/sharepicture" @click.native="moveToTabContent">
                        <img src="../assets/icon/picture.png" >
                        <span>美图分享</span>
                    </router-link>
                </li>
                <li>
                    <router-link replace to="/learningenglish" @click.native="moveToTabContent">
                        <img src="../assets/icon/english.png" >
                        <span>个人英语学习</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="more" @click="moveToTabContent">
            <img src="../assets/icon/xiala_white.png" >
            <span>开始吧</span>
        </div>
    </div>
</template>

<script>
// import { mapState } from 'vuex'
// 引入开发日志文本
import logDetail from '../assets/DevelopmentLogs'
export default {
    name: 'PageHeader',
    data () {
        return {
            // 开发日志文本
           logDetail: logDetail.info,
        }
    },
    props: ['nowDate', 'moveToTabContent'],
    computed: {
        // 改为在app组件中显示
        // ...mapState(["isShowHeaderDevLogCover"]),
        // isShowCoverLog(){
        //     return this.isShowHeaderDevLogCover;
        // },
        // 时间日期模块
        nowTime(){
            // 格式化时分秒
            let hour = this.nowDate.getHours();
            hour = hour < 10 ? '0' + hour : hour;
            let minute = this.nowDate.getMinutes();
            minute = minute < 10 ? '0' + minute : minute;
            let second = this.nowDate.getSeconds();
            second = second < 10 ? '0' + second : second;

            // 格式化年月日
            let year = this.nowDate.getFullYear();
            let month = this.nowDate.getMonth();
            let dates = this.nowDate.getDate();
            let day = this.nowDate.getDay();
            // 通过字符串数组来输出汉字的星期数
            const weekArr = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
            let thisDay = year + '年' + ' ' + (month + 1) + '月' + dates + '日' + ' ' + weekArr[day];

            // 问候语
            let greeting = ''
            if(hour >= 5 && hour < 9){
                greeting = '早安，美好的一天从现在开始'
            } else if (hour >= 9 && hour < 12){
                greeting = '上午好'
            } else if (hour >= 12 && hour < 14){
                greeting = '中午好'
            } else if (hour >= 14 && hour < 19){
                greeting = '下午好'
            } else if (hour >= 19 && hour < 23){
                greeting = '晚上好'
            } else {
                greeting = '晚上好，请注意休息'
            }

            return {
                hour, minute, second, thisDay, greeting
            }
        },
    },
    methods: {
        // 点击显示开发日志
        showDevLogCover(){
            // this.isShowCoverLog = true;  
            // 修改Vuex中的显示状态，并将修改的状态、日志文本作为参数传递
            this.$store.commit('changeIsShowDevLog', {isShow: true, text: this.logDetail});
            //停止页面滚动
            let m = function(e){e.preventDefault();};
            document.body.style.overflow='hidden'; // 隐藏滚动条
            window.addEventListener("touchmove",m,{ passive:false });
        },
        // 点击隐藏开发日志 移到app组件中
        // hideCoverLog(){
        //     // this.isShowCoverLog = false;
        //     // 改动Vuex的数据
        //     this.$store.commit('changeIsShowDevLog',false);
        //     //开启页面滚动
        //     let m =function(e){e.preventDefault();};
        //     document.body.style.overflow=''; // 出现滚动条
        //     window.removeEventListener("touchmove",m,{ passive:true });
        // },
    },
}
</script>

<style lang="less">
// 引入公共样式
@import url("@/assets/styles/common.less");
/* 页面头部样式 */
.pageHeader {
    position: relative;
    text-align: center;
    left: 50%;
    margin-top: 4%;
    transform: translateX(-50%);
    min-width: 1200px;
    min-height: 100vh;
    color: @commonTextColor;
    /* color: rgb(160, 160, 160); */
    text-shadow: 2px 2px 5px #c2c2c2;
    /* background-color: rgba(255, 255, 255, .5); */
}

.pageHeader .logo img {
    width: 120px;
    vertical-align: middle;
}

.pageHeader .logo {
    font-size: 60px;
    font-weight: 700;
    font-style: italic;
}
/* 时间日期模块样式 */
.pageHeader .timer {
    margin-left: 50%;
    margin-top: 4%;
    width: 500px;
    transform: translateX(-50%);
    padding: 20px;
    border-radius: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
    font-size: 40px;
    text-align: center;
    cursor: pointer;
}

.pageHeader .timer span {
    display: inline-block;
    width: 80px;
    height: 80px;
    font-size: 40px;
    text-align: center;
    line-height: 80px;
}

.pageHeader .timer .thisDay {
    text-align: center;
    font-size: 20px;
}

.pageHeader .timer .greeting {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 30px;
}

/* 显示开发日志按钮 */
.pageHeader .timer .logButton {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    animation: ghost 2s infinite;
    // background-color: #fff;
}

/* 漂浮动画 */
@keyframes ghost {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        /* 2D位移 -15px 是向上走 */
        transform: translateY(-5px);
    }
}

.pageHeader .timer .logButton img {
    width: 100%;
    height: 100%;
}

/* 博客、项目按钮 */
.blogButton {
    position: relative;
    display: flex;
    justify-content: space-around;
    margin-top: 3%;
    left: 50%;
    // width: 1200px;
    width: 800px;
    height: 70px;
    transform: translateX(-50%);
    // background-color: rgba(255, 255, 255, .5);
}

.blogButton a {
    width: 200px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    text-decoration: none;
    font-size: 24px;
    color: @commonTextColor;
    border-radius: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
    transition: all 1s;
}

.blogButton a img {
    margin-right: 5px;
    width: 30px;
    height: 30px;
    vertical-align: middle;
}

.blogButton a:hover {
    background-color: rgba(43, 205, 255, 0.6);
}

/* 切换tab栏按钮的样式 */
.tab_list {
    position: relative;
    margin-top: 2%;
    left: 50%;
    width: 1200px;
    height: 150px;
    transform: translateX(-50%);
    /* background-color: rgba(255, 255, 255, .5); */
}

.tab_list ul li {
    float: left;
    padding: 15px;
    width: 16.6666%;
    list-style: none;
    border: 1px solid transparent;
    /* background-color: rgba(255, 255, 255, .5); */
}

/* router-link 最终会被编译为a标签 */
.tab_list ul li a {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    text-decoration: none;
    color: @commonTextColor;
    text-shadow: 5px 5px 5px #c2c2c2;
    border: 1px solid transparent;
}

.tab_list ul li a img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 18px;
    width: 75px;
    height: 75px;
    border-radius: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
    transition: all 1s;
}

.tab_list ul li a img:hover {
    background-color: rgba(255, 142, 55, 0.6);
}

.tab_list ul li a span {
    margin-top: 90px;
    display: block;
    text-align: center;
    text-shadow: none;
}

.more {
    display: none;
    position: relative;
    margin-top: 2%;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    text-decoration: none;
    font-size: 24px;
    /* color: #fff; */
    border-radius: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
    cursor: pointer;
    transition: all 1s;
}

.more img {
    margin-right: 5px;
    width: 27px;
    height: 27px;
    vertical-align: middle;
}

.more:hover {
    background-color: #ee2b29;
}

@media screen and (min-height: 800px) {
    .more {
        display: block;
    }
}

</style>


