<template>
    <div class="tab_content">
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>
export default {
  name: 'TabContent',
}
</script>

<style lang="less">
// 引入公共样式
@import url("@/assets/styles/common.less");

.tab_content {
    position: relative;
    top: 0;
    margin-left: 50%;
    transform: translateX(-50%);
    text-align: center;
    min-width: 1200px;
    color: @commonTextColor;
    text-shadow: 2px 2px 5px #c2c2c2;
}

/* 所有子组件共用，抽取出来放这里了 */
.tab_content .container {
    position: relative;
    /* 内容不足以撑大容器时，会出现滚动bug，设置个最小高度限制 */
    min-height: 860px;
    border-radius: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
    padding: 20px;
    padding-bottom: 40px;
}

.clearfix:after {
    content:'';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.tab_content .container>span {
  font-size: 24px;
}
</style>