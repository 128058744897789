import axios from 'axios';

// 创建axios实例
const axiosInstance = axios.create({
    baseURL: 'https://www.fastmock.site/mock/84d26db8374f4a78fdcf6a938b5d6f7c/data/api',
    timeout: 1000 * 60,
});

// 请求拦截
axiosInstance.interceptors.request.use(
    config => {
        return config;
    }, err => {
        console.log(err);
    }
);

// 响应拦截
axiosInstance.interceptors.response.use(
    config => {
        // console.log("响应信息");
        // console.log(config.data);
        return config;
    }, err => {
        console.log(err);
    }
);

export default axiosInstance;