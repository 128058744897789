<template>
  <div id="app">
    <PageHeader :nowDate="nowDate" :moveToTabContent="moveToTabContent"/>
    <!-- 遮罩层部分 -->
    <!-- 开发日志 -->
    <div class="coverMask" v-if="isShowDevLogCover" @click="hideCover('changeIsShowDevLog')">
        <div class="devlog">
            <div class="logContainer">
                <div class="logTitle">开发日志</div>
                <div>2024-06 临时链接</div>
                <a href="/temp/index.html">江苏灵网</a><br />
                <a href="/dist/index.html">爆破振动智能监测系统</a>
                <div class="logContent">{{devLogDetail}}</div>
            </div>
        </div>
    </div>
    <!-- knowledgeItem -->
    <div class="coverMask" v-if="isShowKnowledgeCover" @click="hideCover('changeIsShowKnowledgeItem')">
        <div class="knowledgeOutcontainer">
            <div class="knowledgeInnerContainer">
                <div class="knowledgeTitle">标题</div>
                <div class="knowledgeContent">{{knowledgeData}}</div>
            </div>
        </div>
    </div>
    <!-- 导航栏 -->
    <div class="nav" :class="isShow">
      <div class="nav_banner">
            <ul>
                <li>
                    <router-link replace to="/sharevideo" @click.native="moveToTabContent">
                        <img src="./assets/icon/bilibili.png">
                        <span>知识视频</span>
                    </router-link>
                </li>
                <li>
                    <router-link replace to="/knowledge" @click.native="moveToTabContent">
                        <img src="./assets/icon/science.png" >
                        <span>野生知识</span>
                    </router-link>
                </li>
                <li>
                    <router-link replace to="/apprecommend" @click.native="moveToTabContent">
                        <img src="./assets/icon/APP.png" >
                        <span>APP推荐</span>
                    </router-link>
                </li>
                <li>
                    <router-link replace to="/foodvideo" @click.native="moveToTabContent">
                        <img src="./assets/icon/food.png" >
                        <span>美食视频</span>
                    </router-link>
                </li>
                <li>
                    <router-link replace to="/sharepicture" @click.native="moveToTabContent">
                        <img src="./assets/icon/picture.png" >
                        <span>美图分享</span>
                    </router-link>
                </li>
                <li>
                    <router-link replace to="/learningenglish" @click.native="moveToTabContent">
                        <img src="./assets/icon/english.png" >
                        <span>英语学习</span>
                    </router-link>
                </li>
                <li>
                  <router-link replace to="/myproject" @click.native="moveToTabContent">
                      <img src="./assets/icon/project.png" >
                      <span>我的项目</span>
                  </router-link>
                </li>
                <li>
                  <a href="https://chiseledpumpkin.github.io">
                    <img src="./assets/icon/blog.png">
                    <span>我的博客</span>
                  </a>
                </li>
                <li>
                  <div class="search">
                    <img src="./assets/icon/fangdajing_white.png">
                  </div>
                </li>
            </ul>
      </div>
    </div>
    <TabContent ref="offsetFromPagesTop" />
    <!-- 返回顶部 -->
    <div class="goback" :class="isShow" @click="goBack">
      <img src="./assets/icon/xiangshang_white.png" alt="">
    </div>
    <!-- 备案信息 -->
    <div class="record">
      <span>本站分享的视频均为外部链接，本站不作任何存储，不提供其他下载渠道</span><br>
      <span>  </span><br>
      <span>Copyright © 2024 南瓜头の知识分享站 All Rights Reserved.</span><br>
      <span>  </span><br>
      <img src="./assets/icon/备案图标.png" alt=""> <span><a href="https://beian.mps.gov.cn/#/query/webSearch?code=32031202000839\" rel="noreferrer\" target="_blank\">苏公网安备32031202000839</a>  <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2022023001号-1</a></span>
    </div>
  </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue'
import TabContent from './components/TabContent.vue'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    PageHeader,
    TabContent
  },
  data() {
    return {
      // 背景图片名
      backgroundImagesArr:{
        '5':['77459508_p0.jpg','78888804_p0.png','80611047_p0.jpg','80942286_p0.png','87007786_p0.jpg','89115849_p0.jpg','92350877_p0.jpg','93032205_p0.jpg'],
        '9':['75907269_p0.jpg','80692343_p0.jpg','80845930_p0.jpg','82794640_p0.jpg','84166210_p0.jpg','85737054_p0.jpg','87270492_p0.jpg','87450923_p0.jpg','88796764_p0.jpg','88943863_p0.png','89398913_p0.jpg'],
        '17':['89458340_p0.jpg','90745035_p0.jpg','91405881_p0.jpg','93776806_p0.jpg','94114516_p0.jpg','94126288_p0.jpg','94657764_p0.jpg','95168283_p0.jpg','95564342_p0.jpg','95681740_p0.jpg','95815717_p0.jpg','96354634_p0.jpg','96479995_p0.jpg','96660627_p0.png'],
        '20':['87450909_p0.jpg','87886874_p0.jpg','88498973_p0.jpg','88564640_p0.jpg','88866731_p0.png','88981625_p0.jpg','89221422_p0.jpg','89659282_p0.jpg','90263208_p0.jpg','90923678_p0.jpg','90945807_p0.jpg','93501209_p0.jpg','95208500_p0.jpg','96001955_p0.png','96879379_p0.jpg'],
      },
      // 是否展示导航栏
      isShow:'notShow',
      moveToTab:1,
      nowDate: new Date(),
    }
  },
  computed:{
        ...mapState(["isShowHeaderDevLogCover","headerDevLogDetail","isShowKnowledgeItemCover","knowledgeItemData"]),
        // 开发日志相关
        isShowDevLogCover(){
          return this.isShowHeaderDevLogCover;
        },
        devLogDetail(){
          return this.headerDevLogDetail;
        },
        // knowledgeItem相关
        isShowKnowledgeCover(){
          return this.isShowKnowledgeItemCover;
        },
        knowledgeData(){
          return this.knowledgeItemData;
        }
  },
  methods: {
    // 根据屏幕滚动位置判断 导航栏与返回顶部模块 是否显示
    handleScroll(){
      // console.log("屏幕正在滚动，当前位置" + window.pageYOffset);
      if (window.pageYOffset >= this.moveToTab - 80) {
        this.isShow = 'show';
      } else {
        this.isShow = 'notShow';
      }
    },

    // 滚动到TabContent组件的位置
    moveToTabContent(){
      // console.log('触发滚动'+ this.moveToTab);

      // 页面滚动之前禁用鼠标滚轮事件，防止页面滚动期间，鼠标滚轮滚动页面出现bug
      // 只能以函数名使用监听事件，否则remove时认为不是同一个东西无法恢复
      window.addEventListener('mousewheel',this.stopDefaultWheel,{ passive: false });

      // window.scroll(0, this.moveToTab); 因为是窗口滚动 所以对象是window
      // 调用动画函数，页面滚动完成后，在回调函数中恢复鼠标滚轮事件
      this.animate(window, this.moveToTab, this.startDefaultWheel);
    },
    
    // 点击了返回顶部模块，窗口滚动到页面最上方
    goBack() {
      window.addEventListener('mousewheel',this.stopDefaultWheel,{ passive: false });
      // window.scroll(0, 0);  
      this.animate(window, 0, this.startDefaultWheel);
    },

    // 动画函数
    animate(obj, target, callback) {
      // 先清除以前的定时器，只保留当前的一个定时器执行
      clearInterval(obj.timer);
      obj.timer = setInterval(function() {
        // 这里需要对 window.pageYOffset 进行取整
        // 如果 window.pageYOffset 包含小数，会出现一直无法符合条件退出动画的情况，导致死循环
        let windowPageYOffset = Math.ceil(window.pageYOffset);
        // 步长值写到定时器的里面
        let step = (target - windowPageYOffset) / 10;
        step = step > 0 ? Math.ceil(step) : Math.floor(step);
        if (windowPageYOffset == target) {
          // 停止动画 本质是停止定时器
          clearInterval(obj.timer);
          // 回调函数写到定时器结束里面
          callback && callback();
        }
        window.scroll(0, windowPageYOffset + step);
      }, 15);
    },

    // 监听事件--阻止鼠标滚轮默认事件
    stopDefaultWheel(){
      event.preventDefault();
    },
    // 回调函数--恢复鼠标滚轮默认事件
    startDefaultWheel(){
      // 移除监听事件来恢复
      window.removeEventListener('mousewheel',this.stopDefaultWheel);
    },
    // 点击隐藏遮罩层
    hideCover(whoCoverIsHide){
      // 改动Vuex的是否展示 
      this.$store.commit(whoCoverIsHide, {isShow: false});
      //开启页面滚动
      let m =function(e){e.preventDefault();};
      document.body.style.overflow=''; // 出现滚动条
      window.removeEventListener("touchmove",m,{ passive:true });
    },
  },
  created(){
    // 请求数据
    this.$store.dispatch('requestData');
  },
  mounted() {
    // 每秒获取一次当前时间
    setInterval(()=>{
      this.nowDate = new Date();
    },1000);

    // vue获取组件距离页面顶部距离 
    // console.log('距离顶部高度', this.$refs.offsetFromPagesTop.$el.offsetTop);
    this.moveToTab = this.$refs.offsetFromPagesTop.$el.offsetTop - 25;

    // 挂载完成时注册监听滚动事件，然后用handleScroll方法进行相应的处理
    window.addEventListener('scroll',this.handleScroll);

    // 根据时间拼接背景图片url
    let nowTime = new Date();
    let nowHour = nowTime.getHours();
    // 测试
    // let nowHour = 20;
    let backgroundImageURL = '';
    if (nowHour>=5 && nowHour<9) {
      let randomNumber = getRandom(0, this.backgroundImagesArr[5].length-1);
      // backgroundImageURL = './assets/background-images/5/' + this.backgroundImagesArr[5][randomNumber];
      backgroundImageURL = require(`./assets/background-images/5/${this.backgroundImagesArr[5][randomNumber]}`);
    } else if (nowHour>=9 && nowHour<17) {
      let randomNumber = getRandom(0, this.backgroundImagesArr[9].length-1);
      // backgroundImageURL = './assets/background-images/9/' + this.backgroundImagesArr[9][randomNumber];
      backgroundImageURL = require(`./assets/background-images/9/${this.backgroundImagesArr[9][randomNumber]}`);
    } else if (nowHour>=17 && nowHour<20) {
      let randomNumber = getRandom(0, this.backgroundImagesArr[17].length-1);
      // backgroundImageURL = './assets/background-images/17/' + this.backgroundImagesArr[17][randomNumber];
      backgroundImageURL = require(`./assets/background-images/17/${this.backgroundImagesArr[17][randomNumber]}`);
    } else if (nowHour>=20 || nowHour<5) {
      let randomNumber = getRandom(0, this.backgroundImagesArr[20].length-1);
      // backgroundImageURL = './assets/background-images/20/' + this.backgroundImagesArr[20][randomNumber];
      backgroundImageURL = require(`./assets/background-images/20/${this.backgroundImagesArr[20][randomNumber]}`);
    }

    // 使用js直接操作元素的方式修改背景图片
    let ele_body = document.body
    ele_body.style.backgroundImage = `url(${backgroundImageURL})`;
    // 禁止选中文字
    ele_body.addEventListener('selectstart', function(e){
      e.preventDefault();
    });

    // 返回指定两个数之间随机整数(包含这两个数)
    function getRandom (min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  },
}
</script>

<style lang="less">

// 公共文本颜色
@import url("@/assets/styles/common.less");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* 背景 */
body {
  position: relative;
  min-height: 100vh;
  background: no-repeat fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  font-family: 'Microsoft YaHei', 'Heiti SC', tahoma, arial, 'Hiragino Sans GB', \\5B8B\4F53, sans-serif;
}

#app {
  padding-bottom: 250px;
}

/* 导航栏样式 */
.nav {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2000px;
  height: 50px;
  z-index: 999;
  background-color: rgba(255, 255, 255, .5);
  transition: all 1s;
}

.show {
  visibility: visible;
  opacity: 1;
}

.notShow {
  visibility: hidden;
  opacity: 0;
}

.nav_banner {
  margin: 0 auto;
  width: 1200px;
  text-align: center;
  /* background-color: rgba(255, 255, 255, .8); */
}

.nav_banner ul li {
    float: left;
    padding: 15px;
    width: 11%;
    height: 100%;
    list-style: none;
    transition: all .5s;
    /* background-color: rgba(255, 255, 255, .5); */
}

/* router-link 最终会被编译为a标签 */
.nav_banner ul li a {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    text-decoration: none;
    color: @commonTextColor;
    text-shadow: 5px 5px 5px #c2c2c2;
    border: 1px solid transparent;
}

.nav_banner ul li:hover {
    background-color: rgba(90, 94, 94, 0.6);
}

.nav_banner ul li a img  {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.nav_banner ul li .search img {
  float: right;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* 返回顶部 */
.goback {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  transition: all 1s;
}

.goback img {
  width: 50px;
  height: 50px;
}

/* 备案信息 */
.record {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  min-width: 1200px;
  color: @commonTextColor;
  font-size: 14px;
}

.record a {
  width: 100%;
  height: 100%;
  text-align: center;
  text-decoration: none;
  color: @commonTextColor;
  text-shadow: 2px 2px 5px #c2c2c2;
}

.record img {
  display: inline-block;
  height: 15px;
  vertical-align: middle;
}


/* 遮罩层样式 */
.coverMask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 999;
}
/* 以下是开发日志模态框 */
.devlog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vh;
    height: 70vh;
    padding: 20px;
    border-radius: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
}

.devlog .logContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55vh;
    height: 65vh;
    border-radius: 20px;
    background-color: rgb(204, 205, 177);
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9) inset;
    color: @darkColor;
    text-align: center;
}

@media screen and (max-height: 700px) {
  .devlog {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 600px;
      height: 70vh;
      padding: 20px;
      border-radius: 30px;
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.6);
      background-color: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(2px);
  }

  .devlog .logContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 550px;
      height: 65vh;
      border-radius: 20px;
      background-color: rgb(204, 205, 177);
      border: 1px solid rgba(255, 255, 255, 0.6);
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9) inset;
      color: @darkColor;
      text-align: center;
  }
}

.devlog .logContainer .logTitle {
    margin-top: 15px;
    font-size: 24px;
}

.devlog .logContainer .logContent {
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    height: 90%;
    overflow: auto;
    white-space: pre;
    // text-align: left;
    font-size: 14px;
    // background-color: #fff;
}

/* 以下是knowledgeItem模态框 */
/* 外层容器 */
.knowledgeOutcontainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 700px;
    padding: 20px;
    border-radius: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
}

/* 内层容器 */
.knowledgeInnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    height: 650px;
    border-radius: 20px;
    background-color: rgb(204, 205, 177);
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9) inset;
    color: #646b8a;
    text-align: center;
}

.knowledgeTitle {
    margin-top: 15px;
    font-size: 24px;
}

.knowledgeContent {
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    height: 90%;
    overflow: auto;
    white-space: pre;
    /* text-align: left; */
    font-size: 14px;
    /* background-color: #fff; */
}

</style>
